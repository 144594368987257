<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">
			<div v-if="$i18n.locale == 'en'">
				<h2 class="display-4 mb-0"
						v-html="$t('smart_staking_1')" />
				<h2 class="mt-12"
						v-html="$t('smart_staking_2')" />

				<h3 v-html="$t('smart_staking_3')" />
				<p v-html="$t('smart_staking_4')" />
				<h3 v-html="$t('smart_staking_5')" />
				<p v-html="$t('smart_staking_6')" />
				<h3 v-html="$t('smart_staking_7')" />
				<p v-html="$t('smart_staking_8')" />
				<h2 v-html="$t('smart_staking_9')" />
				<p v-html="$t('smart_staking_10')" />

				<h3 class="mt-12"
						v-html="$t('smart_staking_11')" />
				<p v-html="$t('smart_staking_12')" />
				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-2.png" />
						<br /> <br /> <b v-html="$t('smart_staking_13')" /><br />
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/staking-b-1.png" /><br /> <br /><b v-html="$t('smart_staking_14')" /></div>
				</div>

				<h3 class="mt-12"
						v-html="$t('smart_staking_15')" />
				<p v-html="$t('smart_staking_16')" />
				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-0.png" />
						<br /> <br /> <b v-html="$t('smart_staking_17')" /><br />
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-1.png" /><br /> <br /><b v-html="$t('smart_staking_18')" /></div>
				</div>

				<h3 class="mt-12"
						v-html="$t('smart_staking_19')" />
				<p v-html="$t('smart_staking_20')" />
				<div class="row ">
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-4.png" /><br />
						<br /><b v-html="$t('smart_staking_21')" />
					</div>

					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-5.png" /><br />
						<br /><b v-html="$t('smart_staking_22')" />
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-6.png" /><br />
						<br /><b v-html="$t('smart_staking_23')" />
					</div>

					<h3 class="mt-12"
							v-html="$t('smart_staking_24')" />
					<p v-html="$t('smart_staking_25')" />
					<div class="row ">

						<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
									 src="https://media.berlcoin.fr/staking-a-7.png" /><br />
							<br /><b v-html="$t('smart_staking_26')" />
							<br /> <b v-html="$t('smart_staking_27')" />
						</div>

						<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
									 src="https://media.berlcoin.fr/staking-b-2.png" /><br /> <br /><b v-html="$t('smart_staking_28')" /></div>
					</div>
				</div>

				<div class="row ">
					<h3 class="mt-12"
							v-html="$t('smart_frenchcontrat_14')" />
					<p v-html="$t('smart_staking_29')+ '<br/>' +  $t('smart_staking_30')" />


				</div>
			</div>

			<div v-if="$i18n.locale == 'fr'">
				<h2 class="display-4 mb-0">
					Staking et sponsoring</h2>
				<h2 class="mt-12">
					Principe de base</h2>
				<p>
				<h3>
					Staking</h3>
				<p>
					Le principe du staking est de geler durant un certain temps des tokens afin d'obtenir une récompense. Cette récompense est proportionnelle à la durée d'immobilisation des tokens. Elle est fixe lors de la conclusion du smart-contract et ne
					peut
					pas être remise en cause.

				</p>
				<h3>
					Sponsoring</h3>
				<p>
					Le Sponsoring (ou parrainage) consiste à obtenir une récompense lorsque qu'un filleul signe un smart-contract de staking. La récompense est de 2% pour le parrain et 1% pour le filleul auquel s'ajoute les récompenses du contrat de staking.
					<br />
				</p>
				<h3>
					KYC/AML</h3>
				<p>
					Le sponsoring n'est autorisé qu'entres personnes identifiables qui ont rempli les données KYC/AML et les ont intégrées dans la blockchain.
					Le smart contract ne s'exécute pas s'il ne trouve pas ces informations sur les deux clés.
					<br />
				</p>
				<h2>
					Un exemple concret</h2>
				<p>
					Valérie parraine Nathalie et souscrit un contrat de staking de 100 Berlcoins pour 5 ans.
				</p>
				<h3 class="mt-12">
					Situation initiale</h3>
				<p> Valérie parraine Nathalie
				<p>
				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-2.png" />
						<br /> <br /> <b> Le compte de Nathalie</b><br />
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/staking-b-1.png" /><br /> <br /><b>Le compte de Valérie </b></div>
				</div>

				<h3 class="mt-12">
					Nathalie enregistre le parrainage de Valérie</h3>
				<p> Dans la section <b>Compte</b>.</p>
				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-0.png" />
						<br /> <br /> <b> Nathalie clique sur le bouton Compte de sponsoring.</b><br />
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-1.png" /><br /> <br /><b>Nathalie saisie la clé publique de valérie et valide. Désormais a chaque investissement en staking de Nathalie, Valérie recevra aussi une récompense. </b> </div>
				</div>

				<h3 class="mt-12">
					Nathalie achete des Berlcoins en staking</h3>
				<p> Dans la section <b>contrat à terme</b>.</p>
				<div class="row ">
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-4.png" /><br />
						<br /><b>Nathalie sélectionne le berlcoin. </b>
					</div>

					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-5.png" /><br />
						<br /><b> Nathalie achète pour 100 Berlcoins à échéance de 5 ans</b>
					</div>
					<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
								 src="https://media.berlcoin.fr/staking-a-6.png" /><br />
						<br /><b>Le compte de Nathalie est débité du montant de son investissement de 100 berlcoins </b>
					</div>

					<h3 class="mt-12">
						Nathalie reçoit son contrat de staking avec la récompense. Nathalie et Valérie reçoivent la récompense de sponsoring.</h3>
					<p> Dans la section <b>Dashboard</b>.</p>
					<div class="row ">

						<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
									 src="https://media.berlcoin.fr/staking-a-7.png" /><br />
							<br /><b>Nathalie reçoit son contrat pour un montant de 100 Berlcoins + 14.40 Berlcoins de récompense pour le staking.
								<br /> Elle recoit en plus 1.14 Berlcoin de sponsoring.</b>
						</div>

						<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
									 src="https://media.berlcoin.fr/staking-b-2.png" /><br /> <br /><b>Valérie reçoit un contrat de 2.29 Berlcoins de staking grâce au sponsoring.</b></div>
					</div>
				</div>

				<div class="row ">
					<h3 class="mt-12">
						Coût de l'opérations</h3>
					<p> Nathalie a payé 0.00001 XLM pour le sponsoring et 0.00001 XLM pour la souscription du contrat de staking : moins de 1 centime d'euro.
						<br /> Valérie n'a eu aucun frais.
					</p>
				</div>
			</div>


		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		if (this.$route.params.lang != undefined) this.$i18n.locale = this.$route.params.lang
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
